
import { Vue, Component, Ref } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import { fireGoogleTag, fireGoogleTagError } from '@/utils/google-tag-manager-helpers';
import { selectTheme } from '@/utils/styling';
import Cookies from 'js-cookie';
import CrosshairIcon from 'vue-feather-icons/icons/CrosshairIcon';
import MapPinIcon from 'vue-feather-icons/icons/MapPinIcon';
import SearchIcon from 'vue-feather-icons/icons/SearchIcon';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import InfoBanner from '@/components/shared/InfoBanner.vue';

const namespace = 'geolocation';

@Component<GeolocationSearch>({
	components: {
		CrosshairIcon,
		MapPinIcon,
		InfoBanner,
		SearchIcon,
		VueGoogleAutocomplete
	}
})
export default class GeolocationSearch extends Vue {
	@Action('geolocationSearch', { namespace }) private geolocationSearch!: () => Promise<GeolocationResult[]>;
	@Action('setGeolocationPayload', { namespace }) private setGeolocationPayload!: (payload: GeolocationPayload) => void;
	@Action('setGeolocationPermission', { namespace }) private setGeolocationPermission!: (permission: string) => void;
	@Action('setGeolocationResults', { namespace }) private setGeolocationResults!: (results: GeolocationResult[] | null) => void;
	@Action('setShowDistance', { namespace }) private setShowDistance!: (showDistance: string[]) => void;
	@Action('resetRestaurantState', { namespace: 'restaurant' }) private resetRestaurantState!: () => void;
	@Getter('getGeolocationPayload', { namespace }) private geolocationPayload!: GeolocationPayload;
	@Getter('getGeolocationResults', { namespace }) private geolocationResults!: GeolocationResult[];
	@Getter('getCountry', { namespace }) private country!: string | string[];
	@Getter('getGeocoder', { namespace }) private geocoder!: any;
	@Getter('getGeolocationPermission', { namespace }) private geolocationPermission!: string;
	@Getter('getShowDistance', { namespace }) private showDistance!: boolean;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant | null;
	@Ref('address') readonly addressRef!: InstanceType<typeof VueGoogleAutocomplete>;

	private loading: boolean = false;
	private geolocationError: string = '';
	private autoRedirect: boolean = true;

	private get bowleroLogo(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/bowlero/bowlero-logo.png`;
	}

	private get background(): string {
		return `${process.env.VUE_APP_IMAGE_BUCKET}/bowlero/search-background.png`;
	}

	/**
	 * Check if user is changing location (coming from the menu page) and set the autoRedirect flag
	 * Prevents the user from being automatically redirected to the same location if geolocation was used, when changing location
	 *
	 * @param {Route} to
	 * @param {Route} from
	 * @param {NavigationGuardNext} next
	 * @return {void}
	 */
	private beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
		if (from.name === 'Menu' || from.name ==='ComingSoon') {
			next(vm => {
				(vm as GeolocationSearch).autoRedirect = false;
			});
		}
		else {
			next();
		}
	}

	private created(): void {
		document.documentElement.classList.remove('modal-open');
		selectTheme('bowlero');
		fireGoogleTag({ name: 'geolocationPage' });

		// If there is a restaurant in the store remove it
		// So that the restaurant will be fetched again when selecting a new location
		if (this.restaurant) {
			this.resetRestaurantState();
		}
	}

	private async mounted(): Promise<void> {
		// Check if user has selected a location in the last day and redirect to that location
		const currentLocationCookie = Cookies.get('currentLocation');
		if (currentLocationCookie) {
			this.loading = true;
			this.redirectToLocation(undefined, currentLocationCookie);
		}
		else {
			await this.searchFromPreviousLocation();

			// Fill the input with name or address from the store if it exists
			// If there is an exact location match use that location's name, if the name is not part of the address
			if (this.geolocationResults && this.geolocationResults.length && this.geolocationResults[0].locationMatched && this.geolocationPayload.name && !this.geolocationPayload.address!.includes(this.geolocationPayload.name)) {
				this.addressRef.update(this.geolocationPayload.name);
			}
			else if (this.geolocationPayload.address) {
				this.addressRef.update(this.geolocationPayload.address);
			}

			// Don't geolocate if the user is changing location, user has to use "use my location" button
			if (this.autoRedirect) {
				await this.checkGeolocationPermissions();
			}
		}
	}
	/**
	 * Perform a location search using the previous location cookie
	 *
	 * @return {Promise<void>}
	 */
	private async searchFromPreviousLocation(): Promise<void> {
		const previousLocationCookie = Cookies.get('previousLocation');
		if (previousLocationCookie) {
			try {
				this.loading
				const parsedLocation = JSON.parse(previousLocationCookie);
				this.autoRedirect = false;
				this.setGeolocationPayload({
					...this.geolocationPayload,
					lat: +parsedLocation.latitude,
					lng: +parsedLocation.longitude,
					name: parsedLocation.name,
					address: parsedLocation.address,
					gmapPlaceId: parsedLocation.gmapPlaceId
				});
				await this.geolocationSearch();
				this.loading = false;
			}
			catch (error) {
				this.loading = false;
				this.geolocationError = this.$t('geolocation.errors.location_search_error');
				fireGoogleTagError({ name: 'errorFetchingLocations', error });
			}
		}
	}

	/**
	 * Check geolocation permissions and set watch for change in permissions
	 *
	 * @return {Promise<void>}
	 */
	private async checkGeolocationPermissions(): Promise<void> {
		if ('permissions' in navigator) {
			const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
			this.setGeolocationPermissionAndSearch(permissionStatus.state as GeolocationPermissionState);

			permissionStatus.onchange = () => {
				this.setGeolocationPermissionAndSearch(permissionStatus.state as GeolocationPermissionState);
			}
		}
	}

	/**
	 * Set geolocation permission and call the geolocateAndSearch method
	 *
	 * @param {GeolocationPermissionState} state
	 * @return {void}
	 */
	private setGeolocationPermissionAndSearch(state: GeolocationPermissionState): void {
		this.setGeolocationPermission(state);

		// If the user has granted permission, set loading to true immediately because the user won't be prompted to enable geolocation
		if (this.geolocationPermission === 'granted') {
			this.loading = true;
			this.geolocateAndSearch();
		}
		else if (this.geolocationPermission === 'prompt') {
			this.geolocateAndSearch();
		}
		else {
			this.geolocationError = this.$t('geolocation.errors.geolocation_denied');
		}
	}

	/**
	 * Get address data from the Google Autocomplete and call the location search
	 *
	 * @param {any} place
	 * @param {any} placeData
	 * @return {Promise<void>}
	 */
	private async handleGooglePlaceSearch(place: any, placeData: any): Promise<void> {
		this.loading = true;

		this.setShowDistance(placeData.types);

		this.setGeolocationPayload({
			...this.geolocationPayload,
			lat: placeData.geometry.location.lat(),
			lng: placeData.geometry.location.lng(),
			name: placeData.name,
			address: placeData.formatted_address,
			gmapPlaceId: placeData.place_id,
			// Show subgroups for city types
			subGroups: placeData.types.includes('locality') || placeData.types.includes('administrative_area_level_3')
		})
		try {
			fireGoogleTag({ name: 'addresSubmit', detail: 'manual' });
			await this.geolocationSearch();

			// Remy returns locationMatched if location searched has a matching place id, name or address of a location in our db
			// So if locationMatched is true, redirect to that location
			if (this.geolocationResults.length && this.geolocationResults[0].locationMatched) {
				this.redirectToLocation(this.geolocationResults[0]);
			}
			else {
				this.loading = false;

				if (!this.geolocationResults.length) {
					fireGoogleTag({ name: 'noResultsFound', detail: this.geolocationPayload.address });
				}
			}
		}
		catch (error) {
			this.loading = false;
			this.geolocationError = this.$t('geolocation.errors.location_search_error');
			fireGoogleTagError({ name: 'errorFetchingLocations', error });
		}
	}


	/**
	 * Enable geolocation, get the current position and geocode, then call the location search
	 *
	 * @return {Promise<void>}
	 */
	private async geolocateAndSearch(): Promise<void> {
		// Check if geolocation is supported by the browser
		if ('geolocation' in navigator) {
			// Get the current position
			navigator.geolocation.getCurrentPosition(async (position) => {
				this.setGeolocationPermission('granted');
				this.loading = true;
				const coords = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				// Get the address from the coordinates (reverse geocode)
				await this.geocoder.geocode({ location: coords }, async (results: any[], status: any) => {
					try {
						if (status === 'OK') {
							const place = results[0];
							this.addressRef.update(place.formatted_address);
							this.setGeolocationPayload({
								...this.geolocationPayload,
								...coords,
								name: place.name,
								address: place.formatted_address,
								gmapPlaceId: place.place_id
							});

							fireGoogleTag({ name: 'addresSubmit', detail: 'auto' });
							await this.geolocationSearch();

							// If there is more than one location within 0.3 miles, don't automatically redirect
							this.autoRedirect = !(this.geolocationResults.filter((location: GeolocationResult) => location.distance <= 0.3).length > 1);

							// If a location is found within 0.3 miles, redirect to that location, otherwise display the results
							if (this.geolocationResults.length && this.geolocationResults[0].distance < 0.3 && this.autoRedirect) {
								this.redirectToLocation(this.geolocationResults[0]);
							}
							else {
								this.loading = false;
								if (!this.geolocationResults.length) {
									fireGoogleTag({ name: 'noResultsFound', detail: this.geolocationPayload.address });
								}
							}
						}
						else {
							this.loading = false;
							this.geolocationError = this.$t('geolocation.errors.geolocation_failed');
							fireGoogleTagError({ name: 'geocodingFailed', error: status });
						}
					}
					catch (error) {
						this.loading = false;
						this.geolocationError = this.$t('geolocation.errors.geolocation_failed');
						fireGoogleTagError({ name: 'errorFetchingLocations', error });
					}
				});
			},
			(error) => {
				this.loading = false;
				this.setGeolocationPermission('denied');
				// If the user denies geolocation, set the results to null so the error message is displayed
				// Catches a pretty specific edge case where the user accepts geolocation temporarily, but denies when prompted again
				this.setGeolocationResults(null);
				this.geolocationError = this.$t('geolocation.errors.geolocation_denied');
				fireGoogleTagError({ name: 'autoLocateDenied', error });
			});
		}
		else {
			this.loading = false;
			this.setGeolocationPermission('denied');
			this.geolocationError = this.$t('geolocation.errors.geolocation_unsupported');
			fireGoogleTagError({ name: 'geolocationNotSupported', error: 'Geolocation not supported' });
		}
	}

	/**
	 * Set cookie for selected location and redirect to the location page
	 * Current location is set to expire in 1 day, and will automatically redirect to that location
	 * Previous location is set to expire in 15 days, and will be used to autofill the address input and search for locations
	 *
	 * @param {string} location
	 * @return {void}
	 */
	private redirectToLocation(location?: GeolocationResult, slug?: string): void {
		let redirectSlug = location ? location.slug : slug;
		// If slug is provided it means that the current location cookie is being used to redirect, so don't set a new previous location cookie
		if (slug) {
			Cookies.set('currentLocation', slug, { expires: 1 });
		}
		else if (location) {
			Cookies.set('currentLocation', location.slug, { expires: 1 });
			Cookies.set('previousLocation', JSON.stringify(location), { expires: 15 });
		}
		this.$router.push({ path: `/${redirectSlug}`, query: { genericTableLocation: 'true', ...this.$route.query }}).catch(() => {});
	}

	/**
	 * Fire Google Tag Manager event when a location card is clicked, then redirect
	 *
	 * @param {GeolocationResult} result
	 * @param {number} index
	 * @return {void}
	 */
	private handleLocationClick(location: GeolocationResult, index: number): void {
		fireGoogleTag({ name: 'clickLocationCard', detail: (index + 1).toString() })
		this.redirectToLocation(location);
	}

	/**
	 * Fill input and search using the first autocomplete result when user presses enter
	 * This doesn't use the results from the autocomplete dropdown, but it use the google autocomplete service
	 * So the results should be the same as what the user sees in the dropdown
	 *
	 * @param {KeyboardEvent} event
	 */
	private async handleKeyPress(event: KeyboardEvent): Promise<void> {
		if (event.key === 'Enter') {
			const input = this.addressRef.$el as HTMLInputElement;
			// Session token will group the autocomplete and place details requests to reduce billing
			const sessionToken = new google.maps.places.AutocompleteSessionToken();
			const autocompleteService = new google.maps.places.AutocompleteService();
			const placesService = new google.maps.places.PlacesService(input);

			// Get place predictions from the input value
			autocompleteService.getPlacePredictions({
				input: input.value,
				componentRestrictions: {
					country: this.country
				},
				sessionToken
			}, (predictions: any[], status: string) => {
				if (status === 'OK') {
					// Get place details from the first prediction
					const placeId = predictions[0].place_id;
					placesService.getDetails({
						placeId,
						sessionToken,
						fields: ['formatted_address', 'name', 'geometry', 'place_id', 'types']
					}, (placeData: any, status: string) => {
						if (status === 'OK') {
							// Update the input with the formatted address and perform the search
							this.addressRef.update(placeData.formatted_address);
							this.addressRef.blur();
							this.handleGooglePlaceSearch(null, placeData);
						}
						else {
							this.geolocationError = this.$t('geolocation.errors.autocomplete_error');
							fireGoogleTagError({ name: 'errorFetchingLocations', error: status });
						}
					});
				}
				else {
					this.geolocationError = this.$t('geolocation.errors.autocomplete_error');
					fireGoogleTagError({ name: 'errorFetchingLocations', error: status });
				}
			});


		}

	}
}
